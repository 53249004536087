
  .remove-item {
    position: absolute;

    .hidden {
      visibility: hidden;
    }

    .icon {
      position: absolute;
      margin-left: 1rem;
      z-index: 99;
    }
  }

  .task-list-item {
    position: relative;
  }

  .task-name {
    position: absolute;
    z-index: 9;
  }
