
  .modal {
    .page-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999999999;

      .page {
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        padding: var(--padding);
        border-radius: var(--border-radius);
        z-index: 100;
        width: 100%;
        max-width: 480px;
        margin: 10vh auto;
        max-height: 80vh;
        min-height: auto;
        position: relative;
      }
    }

    .page-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }
