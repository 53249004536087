
  .new-check-in {
    border-left: 0;
    padding-left: 0;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--half-padding);

    textarea {
      background: var(--bg-color);
      width: 100%;
      min-width: 100%;
      height: 8rem;
      padding: var(--padding);
      resize: none;
      border-top-right-radius: var(--half-padding);
      border-top-left-radius: var(--half-padding);
      font-style: oblique;

      background: #fff;
      border-bottom: 1px solid var(--border-color);
      resize: vertical;
      font-style: normal;
    }

    .actions {
      text-align: right;
      padding: var(--half-padding);

      &:after {
        content: ' ';
        display: block;
        clear: both;
      }

      .options {
        float: left;
        text-align: left;
        margin-top: -0.5rem;

        select {
          margin-bottom: 0.5rem;
          border: 1px solid var(--border-color);
          padding: 0.5rem;
        }

        label {
          display: block;
          font-size: 0.75rem;

          input[type="checkbox"] {
            vertical-align: middle;
            margin-top: -2px;
            margin-right: 0.25rem;
          }
        }
      }

      button {
        display: inline-block;
        width: auto;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 44rem;
    list-style: none !important;
    margin: var(--double-padding) auto;

    @media (max-width: 640px) {
      max-width: 100%;
    }
  }

  .list {
    list-style: none;
    margin-left: var(--padding);

    li {
      border-left: 1px solid var(--border-color);
      padding-left: var(--double-padding);
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: relative;

      &:first-child {
        padding-top: 2rem;
      }

      .timepoint {
        border: 1px solid var(--border-color);
        border-radius: var(--half-padding);
        padding: var(--padding);

        &.checkpointCompletions {
          background: #eefff8;
          border: 2px solid #54e684;
          font-weight: 500;
          color: var(--text-color);

          .icon {
            position: absolute;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            text-align: center;
            line-height: 1;
            padding-top: 0.3rem;
            font-size: 1.25rem;
            top: 50%;
            margin-top: -1rem;
            left: -1rem;
            border: 2px solid #54e684;
            background: #eefff8;
            color: #54e684;
          }
        }

        .tag {
          background: var(--bg-color);
          padding: var(--half-padding);
          display: inline-block;
          border-radius: var(--half-padding);
          line-height: 1;
          font-size: 0.75rem;
          float: right;
          margin-left: 1rem;
        }

        .date {
          font-size: 0.75rem;
          font-style: oblique;
          opacity: 0.5;
          margin-top: var(--half-padding);
        }
      }

      &:before {
        content: ' ';
        display: block;
        border-top: 1px solid var(--border-color);
        width: 2rem;
        position: absolute;
        margin-left: -2rem;
        top: 50%;
      }

      .delete {
        position: absolute;
        right: 0.5rem;
        bottom: 1.5rem;
        font-size: 0.75rem;
        opacity: 0.25;
        line-height: 1;
      }
    }
  }
