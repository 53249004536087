
  header {
    img {
      width: 1.5rem;
      vertical-align: middle;
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
      margin-right: var(--half-padding);
    }
  }
