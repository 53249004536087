
  .colors {
    li {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .color {
      display: block;
      width: 2.75rem;
      height: 2.75rem;

      &.selected {
        outline: 2px dotted #777;
      }
    }
  }
