
  .checklist {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 3rem;

    li {
      margin-bottom: 0.5rem;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    input[type="text"] {
      width: 90%;
    }
  }

  .checkpoint-name {
    width: 100%;
  }

  .checkpoint-remove {
    position: absolute;
    right: 0;
  }

  .checkpoint-task-list {
    position: relative;
    margin-bottom: var(--padding);
  }
