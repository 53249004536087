:root {
  --sans-serif: 'Avenir Next', Helvetica Neue, Helvetica, Arial, sans-serif;
  --serif: Palatino, Times, serif;

  --bg-color: #FBF7F0;
  --light-bg-color: rgba(0, 0, 0, 0.1);
  --text-color: #5a422d;
  --accent-color: #25184e;

  --half-padding: 0.5rem;
  --padding: 1rem;
  --double-padding: 2rem;
  --triple-padding: 4rem;
  --border-radius: 0.66rem;
  --border-color: #eae4de;
}

@import "./css/reset.css";

html, body {
  font-family: var(--sans-serif);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
  background: var(--bg-color);

  @media (max-width: 640px) {
    font-size: 16px;
  }
}

#app {
  width: 100%;
}

nav {
  float: left;
  display: block;
  width: 20rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  @media (max-width: 640px) {
    float: none;
    width: 100%;
    z-index: 999;
    background: var(--bg-color);
    position: sticky;
    box-shadow: 0 0 10px var(--light-bg-color);
  }

  header {
    padding: var(--padding);
    margin: var(--padding) 0;
    width: 100%;

    @media (max-width: 640px) {
      padding: 0;
      margin: 0;
    }

    h1 {
      display: none;

      @media (max-width: 640px) {
        display: block;
        text-align: center;
        position: sticky;
        padding: var(--padding);
        font-size: 1.1rem;
      }
    }

    .icon {
      line-height: 1rem;
      display: block;
      text-align: center;

      @media (max-width: 640px) {
        position: absolute;
        padding: var(--half-padding) var(--padding);
        z-index: 9999;
      }

      svg {
        display: inline-block;
        height: 5rem;

        @media (max-width: 640px) {
          height: 2.5rem;
        }
      }
    }
  }

  .menu {
    @media (max-width: 640px) {
      display: none;

      &.show {
        display: block;
      }
    }

    ul {
      width: 100%;

      li {
        a {
          display: flex;
          align-items: center;
          padding: var(--half-padding) var(--padding);
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;

          &.router-link-active {
            background: #fff;
            border-color: var(--border-color);
          }

          .icon-wrapper {
            margin-right: var(--padding);

            .icon {
              height: var(--double-padding);
              width: var(--double-padding);
              vertical-align: middle;
              text-align: center;

              &.plus {
                opacity: 0.2;
                padding: var(--half-padding);
                border-radius: 50%;
                color: #fff;
                background: #25184e;
                vertical-align: top;
                line-height: 1;
              }
            }
          }

          .name {
            line-height: initial;
          }
        }
      }
    }

    blockquote {
      padding: var(--double-padding);
      font-style: oblique;
      margin-top: var(--padding);
      opacity: 0.6;

      small {
        opacity: 0.6;
        font-size: 80%;
      }
    }
  }
}

.page {
  background: #fff;
  position: relative;
  border: 1px solid var(--border-color);
  border-right: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  margin: var(--double-padding) 0 var(--double-padding) 20rem;
  box-shadow: 0 0 10px var(--light-bg-color);
  min-height: 100vh;

  @media (max-width: 640px) {
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  header {
    background: #fff;
    display: block;
    border-top-left-radius: calc(var(--border-radius) - 1px);
    border-bottom: 3px solid transparent;
    color: #25184e;

    & > div > svg {
      margin-bottom: -10px;
      max-width: 100%;
    }

    @media (max-width: 640px) {
      margin: 0;
      border-radius: 0;
    }

    h2 {
      font-weight: 500;
      font-size: var(--double-padding);
      padding: var(--double-padding);
      padding-bottom: var(--double-padding);

      @media (max-width: 640px) {
        visibility: hidden;
        height: var(--triple-padding);
      }
    }

    .subnav {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      line-height: 1;

      ul {
        display: flex;
        text-align: center;

        li {
          @media (min-width: 641px) {
            margin-right: var(--padding);
          }

          a {
            display: block;
            padding: var(--padding) var(--half-padding);
            background: #fff;
            opacity: 0.5;

            @media (min-width: 641px) {
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              padding: var(--padding);
            }

            &.router-link-active {
              opacity: 1.0;
            }
          }
        }
      }
    }

    .scrollable {
      width: 100%;
      height: 10rem;
      overflow-y: hidden;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .sortable-list {
      white-space: nowrap;
      text-align: center;

      [draggable="true"] {
        opacity: 0.5;
      }

      li {
        display: inline-block;

        &:not(.ignore) {
          cursor: ew-resize;
        }

        .wrapper {
          margin-top: 10rem;
          padding-left: 5rem;
          width: 6rem;
          rotate: -45deg;
          transform-origin: 0% 100%;

          .name {
            min-height: 1.6rem;
            min-width: 12rem;
            text-align: left;

            .line {
              width: 12rem;
              position: absolute;
              margin-left: -4rem;
              margin-top: 0.75rem;
              border-bottom: 3px solid transparent;
              width: 3rem;
              display: block;
            }
          }
        }
      }
    }
  }

  main {
    display: block;
    padding: var(--double-padding);

    @media (max-width: 640px) {
      padding: var(--padding);
    }

    .prose {
      width: 100%;
      max-width: 42rem;
      margin: var(--double-padding) auto;
      font-size: 1rem;

      h1, h2, h3, h4, h5, h6, ul, ol, p, .prose-block {
        margin-bottom: 1rem;
      }

      h1 {
        font-size: 2.5rem;
        font-family: var(--serif);
        color: var(--accent-color);
        margin-bottom: 2rem;

        span {
          border-bottom: 3px solid #ffdf00;
          color: var(--text-color);
          font-style: oblique;
        }
      }

      h2 {
        color: var(--accent-color);
        font-size: 1.5rem;
      }

      p {
        // font-family: var(--serif);
      }

      a:not(.button) {
        border-bottom: 3px solid #ffdf00;
        color: var(--accent-color);
        font-style: oblique;
      }

      ul {
        list-style: disc;
        margin-left: 25px;
      }

      .aspect-ratio {
        height: 0;
        position: relative;
        padding-bottom: 56.25%;

        & > * {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
        }
      }
    }
  }
}

.centered {
  text-align: center;

  img {
    float: none;
    text-align: center;
  }
}

.field {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: var(--half-padding);
  }

  .help-link {
    display: block;
    margin-top: var(--half-padding);
    text-align: right;
  }

  textarea,
  input[type="text"],
  input[type="password"],
  select {
    border-bottom: 2px solid var(--light-bg-color);
    width: 100%;
    padding: var(--half-padding) 0;

    &[disabled] {
      font-style: oblique;
      color: #999;
      background: #fafafa;
      padding: var(--half-padding);
    }
  }

  textarea {
    width: 100%;
    min-width: 100%;
    height: 8rem;
    padding: var(--padding);
    border-radius: var(--half-padding);
    border: 2px solid var(--light-bg-color);
    background: #fff;
    resize: vertical;
  }
}

button, .button {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: var(--half-padding) var(--padding);
  background: var(--text-color);
  color: var(--bg-color);
  border-radius: var(--border-radius);
  text-align: center;

  &.small {
    width: auto;
    display: inline-block;
  }
}

.inline-block {
  display: inline-block;
}

table {
  width: 100%;

  tr:nth-child(odd) {
    background: var(--light-bg-color);
  }

  th, td {
    padding: var(--padding);
  }
}

.list {
  list-style: disc;
  margin-left: 25px;
}

em {
  font-style: oblique;
}

.next-checkpoint {
  margin-top: calc(var(--double-padding) * -1);
  margin-left: calc(var(--double-padding) * -1);
  margin-right: calc(var(--double-padding) * -1);
  margin-bottom: var(--double-padding);
  padding: var(--padding);
  line-height: 1;
  text-align: center;
  border-bottom: 2px solid transparent;
}

strong {
  font-weight: 500;
}

.warning {
  background: var(--bg-color);
  padding: var(--padding);
  margin-bottom: var(--padding);
  border-radius: var(--half-padding);

  a {
    font-weight: 500;
    text-decoration: underline;
  }
}

h2 {
  font-weight: 500;
  font-size: 1.25rem;
}

.float-right {
  float: right;
}

.full-padding {
  padding: var(--padding);
}

.full-margin {
  margin: var(--padding);
}

.relative {
  position: relative;
}
